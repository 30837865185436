import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FormKeysInPage1,
  FormKeysInPage2,
  FormKeysInPage3,
  FormValues,
} from './formEntities';

const initialState: FormValues = {
  personKbn: '0',
  lastNameKanaReporter: null,
  firstNameKanaReporter: null,
  relationship: null,
  admissionYear: '',
  admissionMonth: '',
  insuranceJoinDate: '',
  schoolKbn: '',
  schoolName: '',
  schoolClass: '',
  schoolFaculty: '',
  facultyClass: '',
  departmentName: '',
  departmentKbn: '',
  grade: '',
  gradeFreeComment: null,
  studentNumber: '',
  transferHistory: null,
  occurrenceDate: '',
  occurrenceTime: '',
  occurrencePrefecture: '',
  occurrencePlace: '',
  occurrencePlaceDetail: '',
  activityForm: '',
  transportationForm: null,
  activityContents: null,
  activityDetail: null,
  accidentSituation: '',
  certifier: '',
  injuryPart: '',
  injuryLeftOrRight: null,
  injuryCondition: '',
  injuryName: '',
  hospitalAdmission: '',
  treatmentPeriod: '',
  freeCmnt1: '',
  lastName: '',
  firstName: '',
  lastNameKana: '',
  firstNameKana: '',
  sex: null,
  birthday: '',
  schoolPrefecture: '',
  zipCd: '',
  addrPrefectures: '',
  addrKanaPrefectures: '',
  addrBanchi: '',
  addrBanchiKana: '',
  buildingName: '',
  telephoneNumber: '',
  cellphoneNumber: '',
  mailAddr: '',
  mailAddrConfirm: '',
  gakkensaiAdmission: null,
  mainContactAddr: '',
  freeCmnt2: '',
};
export const formSlice = createSlice({
  name: 'forms',
  initialState: initialState,
  reducers: {
    updateInPage1(state, action: PayloadAction<FormValuesInPage1>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateInPage2(state, action: PayloadAction<FormValuesInPage2>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateInPage3(state, action: PayloadAction<FormValuesInPage3>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});
export const { updateInPage1, updateInPage2, updateInPage3 } =
  formSlice.actions;

export type FormValuesInPage1 = Pick<
  FormValues,
  typeof FormKeysInPage1[number]
>;

export type FormValuesInPage2 = Pick<
  FormValues,
  typeof FormKeysInPage2[number]
>;
export type FormValuesInPage3 = Pick<
  FormValues,
  typeof FormKeysInPage3[number]
>;
