import { useEffect, VFC } from 'react';
import { login } from '../lineID';
import { Outlet } from 'react-router-dom';

const Authenticated: VFC = () => {
  useEffect(() => {
    login();
  }, []);
  return <Outlet></Outlet>;
};
export default Authenticated;
