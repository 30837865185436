import { ReactNode, VFC } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';

type Props = {
  children: ReactNode;
};
const Providers: VFC<Props> = ({ children }) => {
  return (
    <>
      <BrowserRouter>
        <Provider store={store}>{children}</Provider>
      </BrowserRouter>
    </>
  );
};
export default Providers;
